import React from "react";
import './style.scss';
import CatoLink from '../../components/cato-link';
import CatoImg from '../../components/cato-image';

const PartnerBlock = ({img, text, buttonText, link}) => (
    <div className={'partner-block'}>
        <div className={'partner-wrapper'}>
            <div className="img-wrapper">
                <CatoImg img={img} alt="" loading={'lazy'}/>
            </div>
            <div className="text-wrapper" style={{textAlign: 'center'}} dangerouslySetInnerHTML={{__html: text}} />
            <CatoLink path={link} className={'cato-button oval green'}>{buttonText}</CatoLink>
        </div>
    </div>
);

export default PartnerBlock