import React, {Component} from "react"
import {graphql} from "gatsby"
import Layout from "../components/layout"
import CatoButton from '../components/cato-button';
import StickyBannerCTA from '../components/sticky-banner-cta';
import CatoImg from '../components/cato-image';
import {mapGartner, mapStickyBanner, mapCoolInsights, videoSectionMapper} from '../common/utils'
import {GTag, IS_MOBILE, IS_DESKTOP, IS_TABLET, LANG, HOMEPAGE_TRANSLATIONS_DATA} from '../common/consts';
import Slider from 'react-slick';

import PartnerBlock from "../components/partner-block";
import WanTransformationChallenge from "../components/wan-transformation-challange";

import arrowLeft from '../assets/images/nav arrow left.svg';
import arrowRight from '../assets/images/nav arrow right.svg';

import Map from "../components/map";
import VS from "../components/VS";

import '../assets/styles/pages/_home.scss';
import '../assets/styles/responsive/pages/_home-small-desktop.scss';
import '../assets/styles/responsive/pages/_home-tablet.scss';
import '../assets/styles/responsive/pages/_home-mobile.scss';

const topSliderResponsive = [
    {
        breakpoint: 1200,
        settings: {
            slidesToShow: 1,
            nextArrow: <div/>,
            prevArrow: <div/>,
        }
    },{
        breakpoint: 400,
        settings: {
            slidesToShow: 1,
            centerPadding: '0',
            nextArrow: <div/>,
            prevArrow: <div/>,
        }
    }
];

class Home extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isMobile: false,
            isDesktop: false,
            isTablet: false,
        }
    }

    componentDidMount() {
        if(IS_MOBILE) {
            this.setState({...this.state, isMobile: true})
        } else if(IS_DESKTOP){
            this.setState({...this.state, isDesktop: true})
        } else if(IS_TABLET) {
            this.setState({...this.state, isTablet: true})
        }
    }

    bannerSection = (acf) => {
        const {homepageTestimonials} = this.props.data.wpPage;

        const extraBanners = acf.heroCarouselBanners ? acf.heroCarouselBanners.map((banner, i) => {return (
            <div className="gradient evolution" key={`slides-${i}`}>
                {banner.banner_headline && <h2 dangerouslySetInnerHTML={{__html: banner.bannerHeadline}}/> }
                {!this.state.isMobile && banner.bannerImage &&
                <div className="image-wrapper">
                    <CatoImg img={banner.bannerImage}/>
                </div>
                }
                {banner.bannerCtaLabel &&
                <CatoButton
                    label={banner.bannerCtaLabel}
                    path={banner.ctaButtonLink}
                    onClick={this.handleCTAEvent}
                    className="home-banner-button"
                />
                }

                {banner.bannerQuoteText &&
                <div className="text" dangerouslySetInnerHTML={{__html: banner.bannerQuoteText}} />
                }
                {banner.quoteLogo &&
                <div className="logo">
                    <CatoImg img={banner.quoteLogo}/>
                </div>
                }
            </div>
        )}) : null;

        return (
            <section className="banner-section">
                {extraBanners[0]}
                {/*<CatoSlider autoplay={false} autoplaySpeed={10000} pauseOnHover={true} centerPadding={centerPadding}>*/}
                    {/*{slides}*/}
                {/*</CatoSlider>*/}
                {homepageTestimonials.partnerBlock && homepageTestimonials.partnerBlock.length &&
                <div className={'partners'}>
                    <Slider nextArrow={<span><img src={arrowRight} alt="Next"/></span>} prevArrow={<div><img src={arrowLeft} alt="Prev"/></div>} centerPadding={'50px'} centerMode={false} slidesToShow={3} responsive={topSliderResponsive}>
                        {homepageTestimonials.partnerBlock.map((item, key) =>
                            <PartnerBlock key={key}
                                          img={item.partnerLogo}
                                          buttonText={item.buttonText}
                                          link={item.link}
                                          text={item.text}/>
                        )}
                    </Slider>
                </div>
                }
            </section>
        )
    };

    mapSection = (acf) => {
        const counters = acf.hpMapNumbersWithIcons ? acf.hpMapNumbersWithIcons.map(item => {
            const endIcon = item.animate ? item.greenNumberText.slice(-1) : null;
            const finishString = item.animate ? item.greenNumberText.split(endIcon)[0] : item.greenNumberText;
            const isFloat = finishString.indexOf('.') > -1;

            const final = isFloat ? parseFloat(finishString) : item.animate ? parseInt(finishString, 10) : finishString;

            const start = isFloat ? 0.001 : null;

            const step = isFloat ? 0.001 : item.animate ? final > 100 ? 5 : 1 : null;

            const interval = item.animate ? isFloat ? 1 : final > 100 ? 50 : 100 : null;

            return ({
                count: item.animate,
                icon: item.icon && item.icon.localFile ? item.icon.localFile : null,
                finish: final,
                float: isFloat,
                start,
                step,
                interval,
                endIcon,
                bottomText: item.bottomText

            })}) : null;

        return <Map headline={acf.hpMapHeadline} image={acf.hpMapImage} mapNumbersWithIcons={acf.hpMapNumbersWithIcons} counters={counters} />;

    };


    handleCTAEvent = (label) =>
    {
        GTag({category: 'Home', action: 'Main CTA Click', label: `${label.label}, ${label.path}`, nonInteraction: false});
    };

    render() {

        const {data} = this.props;
        const {wpPage} = data;
        const {pageSeoTitle, hpHeroBanners, homepageWANSixPack, homepageVersusSection, homepageMapSection, videoSection} = wpPage;
        const {isDesktop, isTablet} = this.state;
        const banner = hpHeroBanners ? this.bannerSection(hpHeroBanners) : null;
        const vs = homepageVersusSection ? <VS {...{
            heading: homepageVersusSection.versusSectionHeadline || "",
            subHeader: homepageVersusSection.versusSectionLightText || "",
            title_left: homepageVersusSection.versusLeftSideTitleText || "",
            title_right: homepageVersusSection.versusRightSideTitleText || "",
            subtitle_left: homepageVersusSection.versusLeftSideBoldText || "",
            subtitle_right: homepageVersusSection.versusRightSideBoldText || "",
            img_left: homepageVersusSection.versusLeftSideImage.localFile ? homepageVersusSection.versusLeftSideImage.localFile : null,
            img_right: homepageVersusSection.versusRightSideImage.localFile ? homepageVersusSection.versusRightSideImage.localFile : null,
            text_left: homepageVersusSection.versusLeftSideParagraph || "",
            text_right: homepageVersusSection.versusRightSideParagraph || ""
        }} /> : null;
        const map = homepageMapSection ? this.mapSection(homepageMapSection) : null;

        const youtube = videoSection && videoSection.videos && (isDesktop || isTablet) ? videoSectionMapper(videoSection.videoSectionHeadline, videoSection.videos) : null;

        const coolInsights = mapCoolInsights(wpPage);

        const stickyBanner = mapStickyBanner(wpPage);

        const gartner = mapGartner(wpPage);

        return (
            <Layout className="home" yoastData={wpPage.seo || null}
                    lang={LANG.JP}
                    whiteMenu={false}
                    seoTitle={pageSeoTitle.pageSeoTitle}
                    title={wpPage.title}
                    gartner={gartner}
                    seoPath={this.props.location.pathname}
                    translations={HOMEPAGE_TRANSLATIONS_DATA}
                    {...{youtube}}
                    {...{coolInsights}}
            >
                {banner}
                {
                    <WanTransformationChallenge title={homepageWANSixPack.wanTransformationSectionTitle}
                                                items={homepageWANSixPack.wanTransformationSectionItems} />
                }
                {vs}
                {map}
                {
                    (stickyBanner) &&
                    <StickyBannerCTA {...{stickyBanner}}/>
                }
            </Layout>
        )
    }
}

export default Home;


export const query = graphql`
    query {
  wpPage(language: {code: {eq: JP}}, templateFileName: {eq: "front-page.php"}) {
        ...YoastData
        id
        title
        pageSeoTitle: pageSeoTitle {
            pageSeoTitle
        }
        homepageBanner {
            hpTopHeadline
            hpTextUnderImage
            hpButtons {
                hpButtonsButtonLabel
                hpButtonsButtonLink
            }
            hpCustomerLogo {
                altText
                localFile {
                    childImageSharp {
                        fixed(height: 30){
                            ...GatsbyImageSharpFixed_withWebp_noBase64
    
                        }
                    }
                    publicURL
                }
            }
        }
        
        hpHeroBanners {
            heroCarouselBanners {
                bannerHeadline
                bannerImage {
                    altText
                    localFile {
                        publicURL
                    }
                }
                bannerCtaLabel
                ctaButtonLink
                bannerCtaLabel2
                bannerCtaLink2
                bannerQuoteText
                quoteLogo {
                    altText
                    localFile {
                        publicURL
                    }
                }
            }
        }
        homepageTestimonials {
            partnerBlock {
                partnerLogo {
                    altText
                    localFile {
                        childImageSharp {
                            fixed(height: 35){
                                ...GatsbyImageSharpFixed_withWebp_noBase64
                            }
                        }
                        publicURL
                    }
                }
                mobilePartnerLogo: partnerLogo {
                    altText
                    localFile {
                        publicURL
                    }
                }
                text
                link
                buttonText
            }
        }
        homepageWANSixPack {
            wanTransformationSectionTitle
            wanTransformationSectionItems {
                buttonText
                link
                icon {
                    localFile {
                        publicURL
                    }
                }
            }
        }
        homepageVersusSection {
            versusSectionHeadline
            versusSectionLightText
            versusLeftSideImage {
                altText
                localFile {
                    childImageSharp {
                        fluid(maxWidth: 352) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                    }
                    publicURL
                }
            }
            versusLeftSideTitleText
            versusLeftSideBoldText
            versusLeftSideParagraph
            versusRightSideImage {
                altText
                localFile {
                    childImageSharp {
                        fluid(maxWidth: 445) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64 
                        } 
                    }
                    publicURL
                }
            }
            versusRightSideTitleText
            versusRightSideBoldText
            versusRightSideParagraph
        }
        homepageMapSection {
            hpMapHeadline
            hpMapImage {
                altText
                localFile {
                    childImageSharp {
                        fluid(maxWidth: 1600){
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                    }
                    publicURL
                }
            }
            hpMapNumbersWithIcons {
                icon {
                    localFile {
                        childImageSharp {
                            original {
                                width
                                height
                            }
                        }
                        publicURL
                    }
                }
                greenNumberText
                animate
                bottomText
            }
        }
        tryUsStrip {
            tryUsHeadingText
            tryUsButtonText
        }
        structuredOrganizationData {
            organizationData {
                structuredLogo {
                    sourceUrl
                }
                structuredAddress {
                    streetAddress
                    city
                    region
                    country
                    postalCode
                }
                structuredSocialAccounts {
                    socialAccount
                }
                structuredLegalName
                structuredFoundingDate
                structuredFounders {
                founderName
                }
                structuredContact {
                    phoneNumber
                    contactType
                    contactOption
                    languages {
                      language
                    }
                    areasServed {
                      area
                    }
                }
            }
        }
        ...StickyBanner
        ...VideoSection
        ...ResourcesTrio
        ...GartnerStrip
    }
}`
